/*
 * @Author: seventh
 * @Date: 2023-12-18 17:04:24
 * @LastEditors: seventh
 * @LastEditTime: 2023-12-28 15:28:17
 * @FilePath: \pan\src\store\modules\upload.ts
 * @Description: 

 */
import { createSlice } from '@reduxjs/toolkit'

const countSlice = createSlice({
    name:"upload",
    initialState:{
        isPopoverShow: false,
        file: {
            name:"",
        }
    },
    reducers:{
        changeIsPopoverShow(state, { payload }){
            state.isPopoverShow = payload
        },
        changeFile( state, { payload }){
            state.file = payload
        }
    },
})

export const { changeIsPopoverShow, changeFile } = countSlice.actions

export default countSlice.reducer